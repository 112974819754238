var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"inspectorId"}},[_c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm phân loại sức khỏe',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete},on:{"clickDelete":_vm.deleteItems,"clickDowloadSample":_vm.downloadExampleFile,"clickExportExcel":_vm.downloadExportFile,"importFile":function($event){return _vm.importFileExcel($event)},"clickAdd":_vm.showModalCreate,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"columns":_vm.columns,"rows":_vm.dataTable || [],"sort-options":{
        enabled: false,
      },"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"Edit3Icon","size":"18"},on:{"click":function($event){return _vm.showModalEdit(props.row.id)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body ml-2",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}}),_c('edit-health-classification',{attrs:{"id":_vm.modalHealthClasss,"dataDetalClassifyHealthy":_vm.dataDetalClassifyHealthy,"type":_vm.modalType},on:{"handleCreateClassify":_vm.handleCreateClassify}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }