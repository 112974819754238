<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm phân loại sức khỏe"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateClassify"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Người lao động <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customWorker"
        >
          <v-select
            v-model="dataCreateClassify.WorkerId"
            :class="errors.length > 0 ? 'is-invalid':null"
            :reduce="Value => Value.id"
            label="name"
            :options="dataAllWorker || []"
            :placeholder="'Người lao động'"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              {{ option.name }}
            </template>
            <template #selected-option-container="{ option }">
              <div class="vs__selected">
                {{ option.name }}
              </div>
            </template>
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Phân loại sức khỏe <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customClassifyHealth"
        >
          <v-select
            v-model="dataCreateClassify.ClassifyHealthGroup"
            :class="errors.length > 0 ? 'is-invalid':null"
            :reduce="Value => Value.key"
            label="value"
            :options="dataComboboxClassify || []"
            :placeholder="'Phân loại sức khỏe'"
          >
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="mt-1"
        :label="'Năm'"
        label-for="year"
      >
        <b-form-input v-model="dataCreateClassify.Year" />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    dataDetalClassifyHealthy: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataCreateClassify: {
        WorkerId: '',
        ClassifyHealthGroup: '',
        Year: '',
      },
      customWorker: {
        required: 'Người lao động là bắt buộc',
      },
      customClassifyHealth: {
        required: 'Phân loại sức khỏe là bắt buộc',
      },
    }
  },
  computed: {
    ...mapGetters('workerPermit', ['dataAllWorker']),
    ...mapGetters('classify', ['dataComboboxClassify']),
  },
  watch: {
    dataDetalClassifyHealthy(val) {
      if (val && this.type === 'edit') {
        this.dataCreateClassify = {
          WorkerId: this.dataDetalClassifyHealthy.workerId,
          ClassifyHealthGroup: this.dataDetalClassifyHealthy.classifyHealthGroup,
          Year: this.dataDetalClassifyHealthy.year,
        }
      }
    },
  },
  mounted() {
    this.fetchDataComboboxAllWorker()
    this.fetchDataComboboxClassifyHealthy()
  },
  methods: {

    ...mapActions('workerPermit', ['fetchDataComboboxAllWorker']),
    ...mapActions('classify', ['fetchDataComboboxClassifyHealthy']),

    handleCreateClassify(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateClassify', this.dataCreateClassify)
        }
      })
    },
    resetState() {
      this.dataCreateClassify = {
        WorkerId: '',
        ClassifyHealthGroup: '',
        Year: '',
      }
    },
  },
}
</script>
